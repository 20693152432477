<template>
    <div id="form_page_1" class="form-form_page_1">
                <div id="flex_1" class="flex-flex_1"
     style=""
>
   <el-form
          id="form_1" class="form-form_1"
          :inline="false"
          :disabled="disabled"
          labelWidth="100px"
          size="mini"
          label-position="right"
  >

  </el-form>

</div>

    </div>
</template>
<script>
export default {
  //TODO disabled用于全局的表单禁用
  props:["subFormData","disabled"],
  data() {
    return  {
    };
  },
  watch: {
    formParam: {
      //表单内部的值变化，同步回父组件
      handler(newVal) {
        this.$emit("update:subFormData", newVal);
      },
      deep: true,
    },
    subFormData: {
      //props参数变化，重新赋值
      handler(newVal) {
        if (this.newVal && Object.keys(newVal).length) {
          //使用对象融合，避免二级属性取值报错
          this.formParam = Object.assign(this.formParam, newVal);
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  created() {

    if (this.subFormData && Object.keys(this.subFormData).length) {
      //使用对象融合，避免二级属性取值报错
      this.formParam = Object.assign(this.formParam, this.subFormData);
    }
  },
  methods: {
  }
};
</script>
<style lang="less" scoped>
.flex-flex_1 {
        width: 100%;
height: 100%;
position: relative;
    top: 0px;
    left: 0px;
z-index: 0;
padding-top: 0px;
padding-right: 0px;
padding-bottom: 0px;
padding-left: 0px;
margin-top: 0px;
margin-right: 0px;
margin-bottom: 0px;
margin-left: 0px;
box-sizing: border-box;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-content: flex-start;
justify-content: flex-start;
align-items: flex-start;
overflow-x: hidden;
overflow-y: hidden;



border-radius: 0px;
box-shadow: initial;
background-color: #fff;


display: flex;
align-items: flex-start;
justify-content: flex-start;





         .form-form_1 {
        width: 100%;
height: 500px;
position: relative;
    top: 0px;
    left: 0px;
z-index: 0;
padding-top: 30px;
padding-right: 30px;
padding-bottom: 30px;
padding-left: 30px;
margin-top: 0px;
margin-right: 0px;
margin-bottom: 0px;
margin-left: 0px;

























}

}

</style>
