/* eslint-disable key-spacing */
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "0",
    // component: () => import("@/components/Skeleton"),
    component: () => import("@/components/AppSkeleton"),
    redirect: {
      name: "ZhengCeGuanLi_1",
    },
    children: [
      {
        path: "ZhengCeGuanLi_1",
        name: "ZhengCeGuanLi_1",
        component: () => import("@/view/ZhengCeGuanLi_1"),
      },
      {
        path: "ZhengCeShouYe",
        name: "ZhengCeShouYe",
        component: () => import("@/view/ZhengCeShouYe"),
      },
      {
        path: "ZhengCeLieBiao",
        name: "ZhengCeLieBiao",
        component: () => import("@/view/ZhengCeLieBiao"),
      },
      {
        path: "ZhengCeLieBiao_2",
        name: "ZhengCeLieBiao_2",
        component: () => import("@/view/ZhengCeLieBiao_2"),
      },
      {
        path: "YuLan",
        name: "YuLan",
        component: () => import("@/view/YuLan"),
      },
      {
        path: "GuanLiYuanYuLan_1",
        name: "GuanLiYuanYuLan_1",
        component: () => import("@/view/GuanLiYuanYuLan_1"),
      },
    ],
  },
  {
    path: "/ZhengCeLieBiao",
    name: "1649761068651115",
    component: () => import("@/view/ZhengCeLieBiao"),
  },
];
const router = new VueRouter({
  routes
});
export default router;
