<template>
  <div class="editor-render">
    <Toolbar
      :id="`toolbar-container-${componentId}`"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
      v-if="toolbarShow"
    />
    <Editor
      :id="`editor-container-${componentId}`"
      class="editor-container"
      style="height: calc(100% - 82px) !important"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>
<script>
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { FILE_BASE_DOMAIN, EDITOR_UPLOAD } from "@/config/ip";

export default {
  name: "EditorComponents",
  components: { Editor, Toolbar },
  props: {
    value: {
      type: String,
      default: "",
    },
    componentId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {},
      toolbarShow: true,
      editorConfig: { placeholder: "请输入内容...", MENU_CONF: {} },
      mode: "default", // or 'simple'
    };
  },
  created() {
    let self = this;
    let auth = "?Authentication=" + localStorage.getItem("Authentication");
    // this.editor.config.uploadFileName = "file";
    this.editorConfig.MENU_CONF["uploadImage"] = {
      server: EDITOR_UPLOAD,
      fieldName: "file",
      headers: {
        Authentication: localStorage.getItem("Authentication"),
      },
      // 自定义插入图片
      customInsert(res, insertFn) {
        // res 即服务端的返回结果
        if (res.code != "200") {
          self.getDataAxios.notifyError(res.message);
        } else {
          insertFn(FILE_BASE_DOMAIN + res.data.id + auth);
        }
      },
    };
    // this.editor.config.uploadVideoName = "file";
    this.editorConfig.MENU_CONF["uploadVideo"] = {
      server: EDITOR_UPLOAD,
      fieldName: "file",
      headers: {
        Authentication: localStorage.getItem("Authentication"),
      },
      // 自定义插入图片
      customInsert(res, insertFn) {
        // res 即服务端的返回结果
        if (res.code != "200") {
          self.getDataAxios.notifyError(res.message);
        } else {
          insertFn(FILE_BASE_DOMAIN + res.data.id + auth);
        }
      },
    };
  },
  beforeDestroy() {
    if (this.editor == null) {
      return;
    }
    this.editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  watch: {
    // 子组件向父组件传值【对应父组件的:value.sync绑定的值】
    html: {
      handler(val) {
        this.$emit("update:value", val);
      },
    },
    // 监听父组件通过请求变更的值
    value: {
      handler(val) {
        this.html = val;
      },
    },
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.html = this.value;
    },
  },
};
</script>

<style lang="less">
.editor-render {
  padding: 1px;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  .w-e-text-container {
    border: 1px solid #c9d8db;
    height: 100%;
  }
}
</style>
