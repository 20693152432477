import BarComponents from "@/components/BarComponents";
import LineComponents from "@/components/LineComponents";
import PieComponents from "@/components/PieComponents";
import RadarComponents from "@/components/RadarComponents";
import GaugeComponents from "@/components/GaugeComponents";
import FormContainer from "@/components/FormContainer";
import FlowRecord from "@/components/FlowRecord";
import FileUploadComponents from "@/components/fileUpload/FileUploadComponents";
import FileImageUploadComponents from "@/components/fileUpload/FileImageUploadComponents";
import TabelImageComponents from "@/components/fileUpload/TabelImageComponents";
import ImageComponents from "@/components/fileUpload/ImageComponents";
import LinkComponents from "@/components/LinkComponents";
import TelephoneInput from "@/components/TelephoneInput";
import InputIdCard from "@/components/InputIdCard";
import AppTextComponent from "@/components/AppTextComponent";
import TextComponent from "@/components/TextComponent";
import DescriptionsComponent from "@/components/DescriptionsComponent";
import AppImageComponents from "@/components/fileUpload/AppImageComponents";
import AppImageUploadComponents from "@/components/fileUpload/AppImageUploadComponents";
import AppFileUploadComponents from "@/components/fileUpload/AppFileUploadComponents";
import EditorComponents from "@/components/editor/EditorComponents";
import EditorViewComponents from "@/components/editor/EditorViewComponents";

import XinZengZhengCe_1 from "@/components/form/web/XinZengZhengCe_1";
/**
 * 系统设置相关
 */
import baseConfirm from "@/components/BaseConfirm";
import SearchInput from "@/components/BaseSearchInput";
import SearchSelect from "@/components/BaseSearchSelect";
import SearchDate from "@/components/BaseSearchDate";

/**
 * 注册动态组件和设置组件
 */
export default function install(Vue) {
  Vue.component("InputIdCard", InputIdCard);
  Vue.component("TelephoneInput", TelephoneInput);
  Vue.component("FormContainer", FormContainer);
  Vue.component("FlowRecord", FlowRecord);
  Vue.component("FileUploadComponents", FileUploadComponents);
  Vue.component("FileImageUploadComponents", FileImageUploadComponents);
  Vue.component("TabelImageComponents", TabelImageComponents);
  Vue.component("image-components", ImageComponents);
  Vue.component("LinkComponents", LinkComponents);
  Vue.component("bar-components", BarComponents);
  Vue.component("line-components", LineComponents);
  Vue.component("pie-components", PieComponents);
  Vue.component("radar-components", RadarComponents);
  Vue.component("gauge-components", GaugeComponents);
  Vue.component("app-text-component", AppTextComponent);
  Vue.component("text-component", TextComponent);
  Vue.component("descriptions-component", DescriptionsComponent);
  Vue.component("app-image-components", AppImageComponents);
  Vue.component("app-image-upload-components", AppImageUploadComponents);
  Vue.component("app-file-upload-components", AppFileUploadComponents);
  Vue.component("editor-components", EditorComponents);
  Vue.component("editor-view-components", EditorViewComponents);
  Vue.component("Xinzengzhengce1", XinZengZhengCe_1);
  Vue.component("base-confirm", baseConfirm);
  Vue.component("app-search-input", SearchInput);
  Vue.component("app-search-select", SearchSelect);
  Vue.component("app-search-date", SearchDate);
}
