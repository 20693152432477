import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 引入vant【Vue2项目需要 npm i vant@latest-v2】
import vant from 'vant';
import 'vant/lib/index.css';
// 引入axios
import axios from "axios";
// 引入echart
import * as echarts from "echarts";
// 引入复制面板
import VueClipboard from "vue-clipboard2";
// 引入页面样式
import "@/style/index.less";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css"; // 默认主题
import appComponents from "@/components/index";
// 引入iconfont小图标svg库
import "./assets/icon/iconfont.css";
import "./assets/icon/iconfont";
import "./assets/componentsIcon/iconfont.css";
import "./assets/componentsIcon/iconfont";
import utils from "./utils/index";
// 导入时间工具
import moment from "moment";

Vue.use(vant);
Vue.use(utils);
Vue.use(VueClipboard);
// 定义一个新的Message方法，多传入一个offset参数，用于this.$messgae中修改offset中的初始值
const $message = (options) => {
  return ElementUI.Message({
    offset: 65,
  });
};
// 重写一遍Message的方法,将offset写入options
["success", "warning", "info", "error"].forEach((type) => {
  $message[type] = (options) => {
    if (typeof options === "string") {
      options = {
        message: options,
        offset: 65,
      };
    }
    options.type = type;
    return ElementUI.Message(options);
  };
});

// 应用引入的相关插件和组件
Vue.use(ElementUI);
Vue.use(appComponents);
// 给Vue实例绑定对应的方法
Vue.prototype.$message = $message;
Vue.prototype.$alert = ElementUI.MessageBox.alert;
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;

Vue.config.devtools = true;
Vue.directive("focus", {
  inserted: function (e) {
    e.focus();
  },
});

/**
 * 统一的删除弹窗
 * @param confirmText // 删除框的文本提示
 * @param confirmTitle // 删除框的标题
 * @param callback // 删除的返回函数（执行的删除的异步请求操作）
 * @param cancelText // 默认的取消提示
 */
Vue.prototype.$loadingConfirm = function (
  confirmText,
  confirmTitle,
  callback,
  cancelText = "已取消删除"
) {
  this.$confirm(confirmText, confirmTitle, {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    iconClass: "kj-wanring",
    beforeClose: (action, instance, done) => {
      if (action === "confirm") {
        // 删除用户
        instance.confirmButtonLoading = true;
        instance.confirmButtonText = "执行中...";
        // done关闭弹窗，instance返回关闭loading按钮
        callback(done, instance);
      } else {
        // 按钮加载中不让点击取消和关闭
        if (instance.confirmButtonLoading) {
          return;
        } else {
          this.$message.warning(cancelText);
          done();
        }
      }
    },
  }).catch(() => {});
};

export const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
