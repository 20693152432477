/* eslint-disable operator-linebreak */
/**
 * 开发环境IP地址配置：
 * 1、后台请求地址
 * 2、地图服务请求地址配置
 * 3、正地理编码
 * 4、逆地理编码
 * 5、带楼块高度的矢量瓦片地址
 * 6、3d地图建筑地址
 */
export const BASE_SERVER_URL = "";
// export const BASE_SERVER_URL = "http://localhost:8002";
// export const BASE_SERVER_URL = "http://121.40.79.139:8025";
export const FILEUPLOAD = `${BASE_SERVER_URL}/api/file/upload`;
// 富文本上传图片
export const EDITOR_UPLOAD = `${BASE_SERVER_URL}/api/file/upload`;
// 富文本文件预览的根地址
export const FILE_BASE_DOMAIN = `${BASE_SERVER_URL}/api/file/download/`;

//三维地图相关配置
export const TDConfig = {
  //数据地址
  DATA_URL: {
    //小区的倾斜摄影模型
    // BASE_MODEL: "http://192.168.1.33:8008/tileset.json",
    BASE_MODEL:
      "http://47.114.163.199:8002/data-server/3dtiles/building-safe/tileset.json",
  },
  TIAN_MAP_URL: {
    // 矢量底图
    Cvec: "http://t0.tianditu.gov.cn/vec_c/wmts?tk=",
    Wvec: "http://t0.tianditu.gov.cn/vec_w/wmts?tk=",
    // 矢量注记
    Ccva: "http://t0.tianditu.gov.cn/cva_c/wmts?tk=",
    Wcva: "http://t0.tianditu.gov.cn/cva_w/wmts?tk=",
    // 矢量英文注记
    Ceva: "http://t0.tianditu.gov.cn/eva_c/wmts?tk=",
    Weva: "http://t0.tianditu.gov.cn/eva_w/wmts?tk=",
    // 影像底图
    Cimg: "http://t0.tianditu.gov.cn/img_c/wmts?tk=",
    Wimg: "http://t0.tianditu.gov.cn/img_w/wmts?tk=",
    // 影像注记
    Ccia: "http://t0.tianditu.gov.cn/cia_c/wmts?tk=",
    Wcia: "http://t0.tianditu.gov.cn/cia_w/wmts?tk=",
    // 影像英文注记
    Ceia: "http://t0.tianditu.gov.cn/eia_c/wmts?tk=",
    Weia: "http://t0.tianditu.gov.cn/eia_w/wmts?tk=",
    // 地形晕渲
    Cter: "http://t0.tianditu.gov.cn/ter_c/wmts?tk=",
    Wter: "http://t0.tianditu.gov.cn/ter_w/wmts?tk=",
    // 地形注记
    Ccta: "http://t0.tianditu.gov.cn/cta_c/wmts?tk=",
    Wcta: "http://t0.tianditu.gov.cn/cta_w/wmts?tk=",
    // 全球境界
    Cibo: "http://t0.tianditu.gov.cn/ibo_c/wmts?tk=",
    Wibo: "http://t0.tianditu.gov.cn/ibo_w/wmts?tk=",

    // 三维地名 不能直接调用 先写着放在这里
    PlaceName: "https://[ t0-t7 ].tianditu.gov.cn/mapservice/GetTiles?tk=",
    // 三维地形 不能直接调用 先写着放在这里
    Terrain: "https://[ t0-t7 ].tianditu.gov.cn/DataServer?T=elv_c&tk=",
    //天地图的token
    token: "03dd8495c94ffa92a3f1ffe4ed647be8",
  },
  //相机视角补偿
  VIEW_OFFSET: {
    //坐标
    destination: {
      x: 0,
      y: -0.00005,
      z: 407.9,
    },
    //视角
    orientation: {
      heading: 0 * (Math.PI / 180),
      pitch: -90 * (Math.PI / 180),
      roll: 360 * (Math.PI / 180),
    },
  },
  //模型偏移补偿
  MODEL_OFFSET: {
    longitude: 0.000020421,
    latitude: -0.000000587,
    height: 15.6671,
  },
  //geojson数据
  // GEOJSON: {
  //   BASE_URL: "http://192.168.1.33:8118/nczjd_geojson.json",
  //   REVIEW_URL: "http://192.168.1.33:8118/nczjd_review_geojson.json",
  // },
  GEOJSON: {
    BASE_URL:
      "http://47.114.163.199:8002/data-server/geojson/nczjd_geojson.json",
    REVIEW_URL:
      "http://47.114.163.199:8002/data-server/geojson/nczjd_review_geojson.json",
  },
};
